import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PaymentStatusPage } from 'Pages/Payment';
import { usePaymentStatusQuery } from 'Pages/Payment/api';

export const PaymentStatusScreen: FC = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: paymentStatusInfo, isLoading, isError, error } = usePaymentStatusQuery({ id });

  const handleClickRetry = () => {
    if (!paymentStatusInfo) return;

    const { accountId, email, month, sum, year } = paymentStatusInfo.repeatParameters;

    // eslint-disable-next-line max-len
    const currentLink = `http://${document.location.host}/payment/${accountId}?month=${month}&year=${year}&email=${email}&sum=${sum}`;

    document.location.replace(currentLink);
  };

  useEffect(() => {
    if (!paymentStatusInfo) {
      return;
    }

    if (paymentStatusInfo.succeeded) {
      searchParams.set('status', 'success');
    }
    if (!paymentStatusInfo.succeeded) {
      searchParams.set('status', 'failed');
    }

    setSearchParams(searchParams);
  }, [paymentStatusInfo, searchParams, setSearchParams]);

  return (
    <PaymentStatusPage
      isLoading={isLoading}
      isError={isError}
      paymentStatus={paymentStatusInfo}
      error={error as FetchBaseQueryError}
      onClickRetry={handleClickRetry}
    />
  );
};
