import { FC } from 'react';

import { Button } from 'components';

import { ReactComponent as BadSmileIcon } from 'icons/BadSmile.svg';

import styles from './StubNotFound.module.css';

type Props = {
  title: string;
  description: string;
};

export const StubNotFound: FC<Props> = ({ description, title }) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <BadSmileIcon />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <a target="_blank" href="https://t.me/VillagioVip_bot" rel="noreferrer">
        <Button>Поддержка</Button>
      </a>
    </div>
  );
};
