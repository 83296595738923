import { FC } from 'react';

import { ReactComponent as CheckSuccessIcon } from 'icons/CheckSuccess.svg';

import styles from './SuccessStub.module.css';

export const SuccessStub: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <CheckSuccessIcon />
      </div>
      <div className={styles.title}>Платеж выполнен</div>
      <div className={styles.description}>Мы отправили подтверждение оплаты на ваш адрес электронной почты</div>
    </div>
  );
};
