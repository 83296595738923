import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import {
  CreatePassScreen,
  NotFoundScreen,
  PassStatusStubScreen,
  PaymentScreen,
  PolicyScreen,
  UserAgreementScreen,
} from 'Screens';

import { PaymentStatusScreen } from 'Screens/PaymentStatusScreen';

import { MainLayout } from './Layouts';
import { Providers } from './Providers';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <Providers>
          <MainLayout />
        </Providers>
      }
    >
      <Route path="pass-status" element={<PassStatusStubScreen />} />
      <Route path="user-agreement" element={<UserAgreementScreen />} />
      <Route path="policy" element={<PolicyScreen />} />
      <Route path="payment-status">
        <Route path=":id" element={<PaymentStatusScreen />} />
      </Route>
      <Route path="payment">
        <Route path=":id" element={<PaymentScreen />} />
      </Route>
      <Route path=":id" element={<CreatePassScreen />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Route>,
  ),
);
