import { FC, ReactNode } from 'react';

import styles from './Button.module.css';

type PropsButton = {
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export const Button: FC<PropsButton> = ({ children, disabled, isLoading, onClick }) => {
  return (
    <button className={styles.button} disabled={disabled || isLoading} onClick={onClick}>
      {isLoading ? (
        <div className={styles.borderContainer}>
          <div className={styles.loader} />
        </div>
      ) : (
        children
      )}
    </button>
  );
};
