import { FC } from 'react';

import { Button } from 'components';

import { ReactComponent as BadSmileIcon } from 'icons/BadSmile.svg';

import styles from './ErrorStub.module.css';

type Props = {
  title: string;
  description?: string;
  isLoading?: boolean;
  onClickRetry: () => void;
};

export const ErrorStub: FC<Props> = ({ description, title, onClickRetry, isLoading }) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <BadSmileIcon />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>

      <Button isLoading={isLoading} onClick={onClickRetry}>
        Повторить
      </Button>
    </div>
  );
};
