import { FC } from 'react';

import cn from 'classnames';
import { Spinner } from 'components';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ErrorStub, StubNotFound, SuccessStub } from './components';
import { PaymentStatus } from './Payment.entity';
import styles from './PaymentPage.module.css';

type Props = {
  isError: boolean;
  isLoading: boolean;
  error: FetchBaseQueryError | undefined;
  paymentStatus: PaymentStatus | undefined;
  onClickRetry: () => void;
};

export const PaymentStatusPage: FC<Props> = ({ isError, error, isLoading, paymentStatus, onClickRetry }) => {
  if (isLoading) {
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  if (error && error.status === 404) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <StubNotFound title="Платеж не найден" description="" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <ErrorStub title="Произошла ошибка" description="Попробуйте еще раз" onClickRetry={onClickRetry} />
        </div>
      </div>
    );
  }

  if (paymentStatus?.succeeded) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <SuccessStub />
        </div>
      </div>
    );
  }

  if (!paymentStatus?.succeeded) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <ErrorStub title="Платеж не прошёл" description={paymentStatus?.comment} onClickRetry={onClickRetry} />
        </div>
      </div>
    );
  }

  return <div></div>;
};
