import { FC } from 'react';

import './UserAgreementPage.css';

export const UserAgreementPage: FC = () => {
  return (
    <div>
      <div className="rootText">
        <h1 className="class2">
          <span lang="RU">Пользовательское соглашение</span>
        </h1>
        <p className="MsoNormal">
          <span lang="RU"> </span>
        </p>
        <p className="MsoListParagraphCxSpFirst class3">
          <b>
            <span className="class4" lang="RU">
              1. ОБЩИЕ ПОЛОЖЕНИЯ
            </span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpLast class6">
          <span className="class4" lang="RU"></span>
        </p>
        <p className="MsoNormal class6">
          <span className="class4" lang="RU">
            1.1. Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту{' '}
          </span>
          <span className="class4">www</span>
          <span className="class4" lang="RU">
            .
          </span>
          <span className="class4">serv</span>
          <span className="class4" lang="RU">
            -
          </span>
          <span className="class4">point</span>
          <span className="class4" lang="RU">
            .
          </span>
          <span className="class4">ru</span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            1.2. Сайт{' '}
          </span>
          <span className="class4">Serv</span>
          <span className="class4" lang="RU">
            -
          </span>
          <span className="class4">point</span>
          <span className="class4" lang="RU">
            .
          </span>
          <span className="class4">ru</span>
          <span className="class4">
            {' '}
            <span lang="RU">
               (далее – Сайт) является собственностью Общества с ограниченной ответственностью «Монолитстройсервис»
              юридическое лицо, зарегистрированное по законодательству Российской Федерации, ИНН/КПП{' '}
            </span>
          </span>
          <span lang="RU">7719284153/770801001</span>
          <span className="class4" lang="RU">
            , юридический адрес: 101000, г. Москва, Сретенский бульвар, д. 6/1, стр. 2
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            1.3. Настоящее Соглашение регулирует отношения между Администрацией Сайта (далее – Администрация сайта) и
            Пользователем данного Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            1.4. Администрация Сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты
            настоящего Соглашения без уведомления Пользователя.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в
            настоящее Соглашение.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений
            в нем.
          </span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              2.      ОПРЕДЕЛЕНИЯ ТЕРМИНОВ
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              Сайт  -
            </span>
          </b>
          <span className="class4" lang="RU">
            информационно справочный портал, ознакомительного характера, включающий в себя справочную информацию, а
            также описания, фотографии и прочее. Основной целью, которого является предоставление информации об
            оказываемых услугах.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              Администрация Сайта
            </span>
          </b>
          <span className="class4" lang="RU">
             – уполномоченные сотрудники на управления Сайтом, действующие от имени организации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              Пользователь Сайта
            </span>
          </b>
          <span className="class4" lang="RU">
             (далее ‑ Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
            Пользователь, который получил доступ к Личному кабинету Интернет-страницы Заказчика и вступил в
            материально-денежные отношения с Администрацией Сайта далее в Пользовательском соглашении называется
            <b>Пользователь</b>.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              Личный кабинет{' '}
            </span>
          </b>
          <span className="class4" lang="RU">
            - Совокупность защищенных страниц Сайта, созданных в результате регистрации Пользователя, используя которые
            Пользователь имеет возможность формировать счета для дальнейшей оплаты, заказывать услуги компании и
            оплачивать их, получать информацию о коммунальных платежах, получать персональные предложения, участвовать в
            Акциях.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            В Личном кабинете отображается информация о заключенных договорах и оказанных услугах, которыми пользовался
            <b>Пользователь</b>.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              Услуги
            </span>
          </b>
          <span className="class4" lang="RU">
            {`– раздел услуг расположенный на сайте, носит ознакомительный 
            характер, и может быть изменен в любой момент.`}{' '}
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              «Заказ» -{' '}
            </span>
          </b>
          <span className="class4" lang="RU">
            Сообщение Пользователя о намерении приобрести услугу, формируемое посредством заполнения Пользователем
            соответствующих форм на Сайте.<b> </b>
          </span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU"></span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              3.      ПРЕДМЕТ СОГЛАШЕНИЯ
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.1. Предметом настоящего Соглашения является предоставление Пользователю Сайта доступа к содержащейся на
            Сайте информации и оказываемым услугам.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.1.1. Сайт предоставляет Пользователю следующие виды услуг (сервисов):
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst class11">
          <span className="class12" lang="RU">
            ·<span className="class5"></span>
          </span>
          <span className="class10" lang="RU">
            доступ к электронному контенту, с правом просмотра контента;
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle class11">
          <span className="class12" lang="RU">
            ·<span className="class5"></span>
          </span>
          <span className="class10" lang="RU">
            доступ к средствам поиска и навигации Сайта;
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle class11">
          <span className="class12" lang="RU">
            ·<span className="class5"></span>
          </span>
          <span className="class10" lang="RU">
            предоставление Пользователю возможности размещения отзывов, комментариев;
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle class11">
          <span className="class12" lang="RU">
            ·<span className="class5"></span>
          </span>
          <span className="class10" lang="RU">
            по запросу предоставляется доступ к личному кабинету Пользователя
          </span>
        </p>
        <p className="MsoListParagraphCxSpLast class11">
          <span className="class12" lang="RU">
            ·<span className="class5"></span>
          </span>
          <span className="class10" lang="RU">
            иные виды услуг, реализуемые на страницах Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.1.2. Под действие настоящего Соглашения подпадают все существующие (функционирующие) на данный момент
            услуги (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные
            услуги (сервисы) Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.2. Доступ к основным разделам Сайта предоставляется на свободной основе.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается
            присоединившимся к настоящему Соглашению.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской
            Федерации
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU"></span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              4.      ПРАВА И ОБЯЗАННОСТИ СТОРОН
            </span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <span className="class10" lang="RU"></span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              4.1. Администрация Сайта вправе:
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в
            силу с момента публикации новой редакции Соглашения на Сайте.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              4.2. Пользователь вправе:
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.2.1. Получить доступ к использованию Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.2.2. Пользоваться всеми имеющимися на Сайте услугами, а также заказывать Услуги, предлагаемые на Сайте.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.2.3. Задавать любые вопросы, относящиеся к услугам Сайта по реквизитам, которые находятся в разделе Сайта
            «контакты», использовать формы обратной связи.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных
            законодательством Российской Федерации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              4.3. Пользователь Сайта обязуется:
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.1. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.2. Не распространять с использованием Сайта любую недостоверную или конфиденциальную и охраняемую
            законодательством Российской Федерации информацию о физических либо юридических лицах.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.3. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой
            законодательством Российской Федерации информации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.4. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия
            Администрации Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7. Не использовать сервисы Сайта с целью:
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7.1. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует
            насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному,
            социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц,
            организаций, органов власти.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7.2. побуждения к совершению противоправных действий, а также содействия лицам, действия которых
            направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7.3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7.4. ущемления прав меньшинств.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.7.5. представления себя за другого человека или представителя организации и (или) сообщества без
            достаточных на то прав, в том числе за сотрудников данного Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.3.8. При получении доступа к Личном кабинету Заказчика, проверить полноту, актуальность и легитимность
            всех загруженных ранее информационных материалов, а именно: ФИО,{' '}
          </span>
          <span className="class4">email</span>
          <span className="class4" lang="RU">
            , логин, все виды договоров и срок их действия, список заказанных услуг, список оказанных услуг. В случае
            наличия материалов, противоречащих данному Соглашению, Пользователь обязуется сообщить об этом администрации
            сайта по средствам телефонной связи и/или использовать формы обратной связи.  В случае, если Пользователь не
            сообщил о наличии и/или отсутствии каких либо материалов в разделе «личный кабинет», он гарантирует их
            достоверность и подтверждает, что они используются Администрацией сайта правомерно.
          </span>
        </p>
        <p className="MsoNormal class7">
          <b>
            <span className="class8" lang="RU">
              4.4. Пользователю запрещается:
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства
            или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта;
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.2. Нарушать надлежащее функционирование Сайта;
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой
            информации, документов или материалов любыми средствами, которые специально не представлены сервисами
            данного Сайта;
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному
            Сайту, а также к любым услугам, предлагаемым на Сайте;
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.5. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.6. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом
            Пользователе Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            4.4.7. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации,
            а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права Сайта  или
            других лиц.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU"></span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              5.      ИСПОЛЬЗОВАНИЕ САЙТА
            </span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <span className="class10" lang="RU"></span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией Сайта<i>.</i>
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено
            любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия
            Администрации Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими
            правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.4. Заказ Услуги, предлагаемой на Сайте, может потребовать создания учётной записи
            Пользователя/регистрации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной
            записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя
            учётной записи.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.6. Пользователь должен незамедлительно уведомить Администрацию Сайта о несанкционированном использовании
            его учётной записи или пароля или любом другом нарушении системы безопасности.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.7. Администрация Сайта обладает правом в одностороннем порядке аннулировать учетную запись Пользователя
            без уведомления Пользователя.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.7. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия оказания
            услуг, предоставляемых на Сайте.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.9. Администрация Сайта имеет право в любое время без уведомления Пользователя вносить изменения в список
            услуг предлагаемых на Сайте.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.10. Документы, указанные в пунктах 5.10.1 - 5.10.2 настоящего Соглашения регулируют в соответствующей
            части и распространяют свое действие на использование Пользователем Сайта. В настоящее Соглашение включены
            следующие документы:
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.10.1. Политика конфиденциальности;
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.10.2. Оферта
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            5.11. Любой из документов, перечисленных в пункте 5.10. настоящего Соглашения может подлежать обновлению.
            Изменения вступают в силу с момента их опубликования на Сайте.
          </span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              6.      ОТВЕТСТВЕННОСТЬ
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения
            любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям
            другого Пользователя, Администрацией Сайта не возмещаются.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.2. Администрация Сайта не несет ответственности за:
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также
            любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических
            средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими
            средствами.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            6.2.4. Размещенную Пользователем информацию, ее характер и достоверность.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU"></span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              7.      НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ
            </span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <span className="class10" lang="RU"></span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            7.1. Администрация Сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если
            раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта
            либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права
            Администрации Сайта или в права других Пользователей Сайта.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            7.2. Администрация Сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой
            для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий
            настоящего Соглашения, защиты прав или безопасности название организации, Пользователей.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            7.3. Администрация Сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство
            Российской Федерации требует или разрешает такое раскрытие.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            7.4. Администрация Сайта вправе без предварительного уведомления Пользователя прекратить и (или)
            заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных
            документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине
            технической неполадки или проблемы.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            7.5. Администрация Сайта не несет ответственности перед Пользователем или третьими лицами за прекращение
            доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа,
            содержащего условия пользования Сайтом.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU"></span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              8.      РАЗРЕШЕНИЕ СПОРОВ
            </span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <span className="class10" lang="RU"></span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным
            условием до обращения в суд является предъявление претензии (письменного предложения о добровольном
            урегулировании спора).
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя
            претензии о результатах рассмотрения претензии.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за
            защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение 60 дневного срока
            после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с
            законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска
            погашаются исковой давностью.
          </span>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU"></span>
          </b>
        </p>
        <p className="MsoNormal class9">
          <b>
            <span className="class10" lang="RU">
              9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
            </span>
          </b>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            9.1. Администрация Сайта не принимает встречные предложения от Пользователя относительно изменений
            настоящего Пользовательского соглашения.
          </span>
        </p>
        <p className="MsoNormal class7">
          <span className="class8" lang="RU">
            9.2. Отзывы и комментарии Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и
            могут быть использованы Администрацией Сайта без ограничений.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="RU"> </span>
        </p>
      </div>
    </div>
  );
};
