import { FC } from 'react';

import cn from 'classnames';
import { Button, Input, Spinner } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';

import { ErrorStub, StubNotFound } from './components';
import { PaymentInfo } from './Payment.entity';
import { validationPayment } from './Payment.helpers';
import styles from './PaymentPage.module.css';

type Props = {
  status: 'success' | 'error';
  paymentInfo: PaymentInfo | undefined;
  onSubmit: (values: { email: string }) => void;
  isLoadingPayment: boolean;
  isLoadingInfo: boolean;
  paymentError: boolean;
  email: string;
  onClickRetry: () => void;
};

export const PaymentPage: FC<Props> = ({
  isLoadingPayment,
  isLoadingInfo,
  paymentInfo,
  paymentError,
  email,
  onSubmit,
  onClickRetry,
}) => {
  if (paymentError && !isLoadingInfo) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <StubNotFound
            title="Платеж не найден"
            description="К сожалению, мы не смогли найти платеж. Если у вас остались вопросы – напишите в поддержку."
          />
        </div>
      </div>
    );
  }

  if (isLoadingInfo) {
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  if (!paymentInfo) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.container, styles.stub)}>
          <ErrorStub
            title="Произошла ошибка"
            description="Произошла ошибка, попробуйте позже"
            onClickRetry={onClickRetry}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Formik
        initialValues={{ email }}
        onSubmit={onSubmit}
        validationSchema={validationPayment}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <Form>
            <div className={styles.container}>
              <div className={styles.logo}>
                <img src="/img/logo.png" width={88} />
              </div>
              <div className={styles.blockMainInfo}>
                <div className={styles.transactionNumber}>{paymentInfo.title}</div>
                <div className={styles.paymentAmount}>
                  <div className={styles.amount}>
                    {paymentInfo.toPaySum.toLocaleString('ru', {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                  <div className={styles.symbol}>₽</div>
                </div>
                <div className={styles.descriptions}>{paymentInfo.description}</div>
              </div>

              <div className={styles.emailBlock}>
                <Field name="email">
                  {({ field: { name, value }, form: { setFieldValue, errors } }: FieldProps) => (
                    <Input
                      label="Электронная почта для чека"
                      value={value}
                      onChange={(e) => setFieldValue(name, e.target.value)}
                      placeholder="Например, name@domain.ru"
                      errorText={errors[name] ? String(errors[name]) : ''}
                    />
                  )}
                </Field>
              </div>

              <div className={styles.footer}>
                <Button isLoading={isLoadingPayment}>Оплатить</Button>

                <div className={styles.politic}>
                  {'Нажимая кнопку «Оплатить», я соглашаюсь с условиями с условиями '}
                  <a target="_blank" href="/user-agreement">
                    пользовательского соглашения
                  </a>{' '}
                  и{' '}
                  <a target="_blank" href="/policy">
                    политики конфиденциальности
                  </a>
                </div>
                <div className={styles.paymentSystems}>
                  <img src="/img/payment_systems.png" width={165} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
