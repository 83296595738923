import { FC } from 'react';

import './PolicyPage.css';

export const PolicyPage: FC = () => {
  return (
    <div className="rootText">
      <div className="WordSection1">
        <p className="custom-class-2">
          <b>
            <span className="custom-class-3">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</span>
          </b>
        </p>
        <p className="custom-class-2">
          <b>
            <span className="custom-class-3"></span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">г.  Москва</span>
          <span className="custom-class-6"></span>
          <span className="custom-class-5">« 06» сентября  2016 г.</span>
          <span className="custom-class-7"></span>
        </p>
        <p className="custom-class-8">
          <span className="custom-class-9">
            Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в
            отношении всей информации, которую
          </span>
          <span className="custom-class-9">компания “Монолитстройсервис”,</span>
          <span className="custom-class-5"> расположенный</span>
          <span className="custom-class-5">на доменном имени</span>
          <span className="custom-class-5"></span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">, может получить о Пользователе во время использования сайта</span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">
            <span>, программ и продуктов компании.</span>
          </span>
        </p>
        <p className="custom-class-10">
          <b>
            <span className="custom-class-5">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.   В настоящей Политике конфиденциальности используются следующие термины:
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">1.1.1. «Администрация сайта</span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">
            (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени
            Название организации,  которые организуют и (или) осуществляет обработку персональных данных, а также
            определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия
            (операции), совершаемые с персональными данными.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных).
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без использования таких средств с персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
            удаление, уничтожение персональных данных.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим
            доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта
            персональных данных или наличия иного законного основания.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">1.1.5. «Пользователь сайта</span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">
            <span>
              (далее ‑ Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт
              компании.
            </span>
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
            пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
            попытке открыть страницу соответствующего сайта.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
          </span>
        </p>
        <p className="custom-class-11">
          <b>
            <span className="custom-class-3">2.   ОБЩИЕ ПОЛОЖЕНИЯ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">2.1.   Использование Пользователем сайта</span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">
            означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных
            Пользователя.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            2.2.   В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
            использование сайта www.serv-point.ru.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            2.3.   Настоящая Политика конфиденциальности применяется только к сайту
          </span>
          <span>
            <a href="http://www.serv-point.ru">
              <span className="custom-class-3" lang="EN-US">
                www
              </span>
              <span className="custom-class-3">.</span>
              <span className="custom-class-5" lang="EN-US">
                serv
              </span>
              <span className="custom-class-5">-</span>
              <span className="custom-class-5" lang="EN-US">
                point
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                ru
              </span>
            </a>
          </span>
          <span className="custom-class-5">«</span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">
            ». Администрация сайта не контролирует и не несет ответственность за сайты третьих лиц, на которые
            Пользователь может перейти по ссылкам, доступным на сайте
          </span>
          <span>
            <a href="http://www.serv-point.ru">
              <span className="custom-class-12" lang="EN-US">
                www
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                serv
              </span>
              <span className="custom-class-5">-</span>
              <span className="custom-class-5" lang="EN-US">
                point
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                ru
              </span>
            </a>
          </span>
          <span className="custom-class-5">.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            2.4.   Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем
            сайта
          </span>
          <span>
            <a href="http://www.serv-point.ru">
              <span className="custom-class-5" lang="EN-US">
                www
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                serv
              </span>
              <span className="custom-class-5">-</span>
              <span className="custom-class-5" lang="EN-US">
                point
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                ru
              </span>
            </a>
          </span>
          <span className="custom-class-5">.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5"></span>
        </p>
        <p className="custom-class-13">
          <b>
            <span className="custom-class-3">3.   ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</span>
          </b>
        </p>
        <p className="custom-class-14">
          <b>
            <span className="custom-class-5"></span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            3.1.   Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта
          </span>
          <span>
            <a href="http://www.serv-point.ru">
              <span className="custom-class-12" lang="EN-US">
                www
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                serv
              </span>
              <span className="custom-class-5">-</span>
              <span className="custom-class-5" lang="EN-US">
                point
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                ru
              </span>
            </a>
          </span>
          <span className="custom-class-5">
            по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь
            предоставляет по запросу Администрации сайта при обращаение в компанию ООО «Монолитстройсервис» или при
            оформлении заказа для приобретения услуги.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
            предоставляются Пользователем путём обращения в Загородную Сервисную службу
          </span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">
            <span>
              (ООО «Монолитстройсервис»), будут отображаться в разделе  «Личный кабинет»  и включают в себя следующую
              информацию:
            </span>
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.1. фамилию, имя, отчество Пользователя;</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.2. контактный телефон  Пользователя;</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.3. адрес электронной почты (e-mail);</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.4. адрес проживания;</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.5. заключенные договора на оказание услуг</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.5 счет на оказание услуг</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.2.6 счета за коммунальные услуги.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            3.3. Личный кабинет защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков
            и при посещении страниц, на которых установлен статистический скрипт системы {'("пиксель")'}:
          </span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">IP адрес;</span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">информация из cookies;</span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">
            информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);
          </span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">время доступа;</span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">адрес страницы, на которой расположен рекламный блок;</span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-16">·</span>
          <span className="custom-class-17">реферер (адрес предыдущей страницы).</span>
        </p>
        <p className="custom-class-15">
          <span className="custom-class-17">
            3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта Загородной Сервисной  службы
          </span>
          <span className="custom-class-18">Servicepoint</span>
          <span className="custom-class-17">, требующим авторизации.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">3.3.2. Загородная Сервисная служба</span>
          <span className="custom-class-9">Servicepoint</span>
          <span className="custom-class-12">
            осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью
            выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-19">
            3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и
            операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев,
            предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
          </span>
        </p>
        <p className="custom-class-11">
          <b>
            <span className="custom-class-3">4.   ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для оформления заказа и (или) заключения
            Договора оказания услуг дистанционным способом с  Загородной Сервисной  службой
          </span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта
          </span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся
            использования Сайта Загородной Сервисной службы
          </span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">, оказания услуг, обработка запросов и заявок от Пользователя.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.6. Создания учетной записи для совершения заказов, если Пользователь дал согласие на создание учетной
            записи.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">4.1.7. Уведомления Пользователя Сайта о состоянии Заказа.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа,
            определения права на получение кредитной линии Пользователем.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем
            связанных с использованием Сайта.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений,
            информации о ценах, новостной рассылки и иных сведений от имени Загородной Сервисной службы
          </span>
          <span className="custom-class-9">Servicepoint</span>
          <span className="custom-class-12">или от имени партнеров.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">4.1.11. Осуществления рекламной деятельности с согласия Пользователя.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров Загородной Сервисной службы
          </span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5">с целью получения продуктов, обновлений и услуг.</span>
        </p>
        <p className="custom-class-20">
          <b>
            <span className="custom-class-3">5.  СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ</span>
          </b>
          <b>
            <span className="custom-class-21"></span>
          </b>
          <b>
            <span className="custom-class-3">ИНФОРМАЦИИ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
            способом, в том числе в информационных системах персональных данных с использованием средств автоматизации
            или без использования таких средств.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим
            лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно
            в целях выполнения заказа Пользователя, оформленного на Сайте Загородной Сервисной службы
          </span>
          <span className="custom-class-5">Servicepoint</span>
          <span className="custom-class-5"></span>
          <span className="custom-class-5">www</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5">.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти
            Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате
            или разглашении персональных данных.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной
            информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий третьих лиц.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков
            или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
          </span>
        </p>
        <p className="custom-class-20">
          <b>
            <span className="custom-class-3">6.  ОБЯЗАТЕЛЬСТВА СТОРОН</span>
          </b>
        </p>
        <p className="custom-class-4">
          <b>
            <span className="custom-class-5">6.1. Пользователь обязан:</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
            информации.
          </span>
        </p>
        <p className="custom-class-4">
          <b>
            <span className="custom-class-5">6.2. Администрация сайта обязана:</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики
            конфиденциальности.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
            письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение
            иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3.
            настоящей Политики Конфиденциальности.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
            согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента
            обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите
            прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных
            или неправомерных действий.
          </span>
        </p>
        <p className="custom-class-22">
          <b>
            <span className="custom-class-3">7.  ОТВЕТСТВЕННОСТЬ СТОРОН</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые
            Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством
            Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
            Конфиденциальности.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт
            ответственность, если данная конфиденциальная информация:
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">7.2.1. Стала публичным достоянием до её утраты или разглашения.</span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">7.2.3. Была разглашена с согласия Пользователя.</span>
        </p>
        <p className="custom-class-22">
          <b>
            <span className="custom-class-3">8.  РАЗРЕШЕНИЕ СПОРОВ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">8</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">1</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">
            До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта
          </span>
          <span>
            <a href="http://www.serv-point.ru">
              <span className="custom-class-5" lang="EN-US">
                www
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                serv
              </span>
              <span className="custom-class-5">-</span>
              <span className="custom-class-5" lang="EN-US">
                point
              </span>
              <span className="custom-class-5">.</span>
              <span className="custom-class-5" lang="EN-US">
                ru
              </span>
            </a>
          </span>
          <span className="custom-class-5">
            <span>
              и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
              добровольном урегулировании спора).
            </span>
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет
            заявителя претензии о результатах рассмотрения претензии.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
            действующим законодательством Российской Федерации.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">8</span>
          <span className="custom-class-5">
            .4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта
            применяется действующее законодательство Российской Федерации.
          </span>
        </p>
        <p className="custom-class-22">
          <b>
            <span className="custom-class-3">9.  ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</span>
          </b>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
            Пользователя.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не
            предусмотрено новой редакцией Политики конфиденциальности.
          </span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5">
            9.4. Действующая Политика конфиденциальности размещена на странице по адресу www.
          </span>
          <span className="custom-class-5">serv</span>
          <span className="custom-class-5">-</span>
          <span className="custom-class-5">point</span>
          <span className="custom-class-5">.</span>
          <span className="custom-class-5">ru</span>
          <span className="custom-class-5"></span>
        </p>
        <p className="custom-class-4">
          <span className="custom-class-5"></span>
        </p>
        <br></br>
        <p className="custom-class-4">
          <b>
            <span className="custom-class-5">Обновлено «</span>
            <span className="custom-class-5">06</span>
            <span className="custom-class-5">» сентября 2016 г.</span>
          </b>
        </p>
      </div>
    </div>
  );
};
